var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-professional-info"},[_c('settings-card',{attrs:{"section":_vm.getASection('professional_info')},scopedSlots:_vm._u([{key:"header-text",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.$t("settings.my_details.professional_information.title")))])]},proxy:true},{key:"header-save__btn",fn:function(){return [_c('footer-button',{attrs:{"section":_vm.getASection('professional_info')}})]},proxy:true},{key:"card-content",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t( "settings.my_details.professional_information.labels.affiliation" )))]),_c('p',[_vm._v(" "+_vm._s(_vm.allProfile.affiliation ? _vm.allProfile.affiliation.name : "-")+" ")])]),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t( "settings.my_details.professional_information.labels.professional_area" )))]),_c('p',[_vm._v(" "+_vm._s(_vm.allProfile.role ? _vm.allProfile.role.name : "-")+" ")])]),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t( "settings.my_details.professional_information.labels.level_of_education.title" )))]),_c('p',[_vm._v(" "+_vm._s(_vm.allProfile.education ? _vm.allProfile.education.name : "-")+" ")])])],1)]},proxy:true},{key:"card-edit-content",fn:function(){return [_c('div',{staticClass:"md-pi__edit sc-content__edit--wrapper"},[_c('b-row',{staticClass:"md-pi__form"},[_c('b-col',{attrs:{"cols":"6"}},[_c('Select',{attrs:{"label":_vm.$t(
                  'settings.my_details.professional_information.labels.affiliation'
                ) + '*',"placeholder":_vm.$t('onboarding.affiliation.heading'),"required":true,"dropdownArr":_vm.affiliation.filter(i => i.valid === true),"value":_vm.form.affiliation ? _vm.form.affiliation.name : null},on:{"click":function($event){return _vm.onSelect($event, 'affiliation', _vm.form)}}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('Select',{attrs:{"label":_vm.$t('onboarding.job.current_role.hint') + '*',"placeholder":_vm.$t('onboarding.job.current_role.hint'),"required":true,"dropdownArr":_vm.roles,"value":_vm.form.role ? _vm.form.role.name : null},on:{"click":function($event){return _vm.onSelect($event, 'role', _vm.form)}}})],1),(_vm.form.role && _vm.form.role.id === 47)?_c('b-col',{attrs:{"cols":"6"}}):_vm._e(),(_vm.form.role && _vm.form.role.id === 47)?_c('b-col',{attrs:{"cols":"6"}},[_c('CInput',{staticClass:"md-pi__form--role",attrs:{"id":"role","label":_vm.$t('onboarding.job.other.please_specify'),"placeholder":_vm.$t('onboarding.job.current_role.hint'),"required":true,"value":_vm.form.udf_role,"maxlength":64},on:{"input":e => (_vm.form.udf_role = e)}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('Select',{attrs:{"label":_vm.$t(
                  'settings.my_details.professional_information.labels.level_of_education.optional'
                ),"placeholder":_vm.$t(
                  'settings.my_details.professional_information.labels.level_of_education.title'
                ),"dropdownArr":_vm.education.filter(i => i.valid === true),"value":_vm.form.education ? _vm.form.education.name : null},on:{"click":function($event){return _vm.onSelect($event, 'education', _vm.form)}}})],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }