<template>
  <div class="md-professional-info">
    <settings-card :section="getASection('professional_info')">
      <template v-slot:header-text>
        <h5>{{ $t("settings.my_details.professional_information.title") }}</h5>
      </template>
      <template v-slot:header-save__btn>
        <footer-button
          :section="getASection('professional_info')"
        ></footer-button>
      </template>
      <template v-slot:card-content>
        <b-row>
          <b-col cols="6" class="mb-3">
            <label>{{
              $t(
                "settings.my_details.professional_information.labels.affiliation"
              )
            }}</label>
            <p>
              {{ allProfile.affiliation ? allProfile.affiliation.name : "-" }}
            </p>
          </b-col>
          <b-col cols="6" class="mb-3">
            <label>{{
              $t(
                "settings.my_details.professional_information.labels.professional_area"
              )
            }}</label>
            <p>
              {{ allProfile.role ? allProfile.role.name : "-" }}
            </p>
          </b-col>
          <b-col cols="6" class="mb-3">
            <label>{{
              $t(
                "settings.my_details.professional_information.labels.level_of_education.title"
              )
            }}</label>
            <p>
              {{ allProfile.education ? allProfile.education.name : "-" }}
            </p>
          </b-col>
        </b-row>
      </template>
      <template v-slot:card-edit-content>
        <div class="md-pi__edit sc-content__edit--wrapper">
          <b-row class="md-pi__form">
            <b-col cols="6">
              <Select
                :label="
                  $t(
                    'settings.my_details.professional_information.labels.affiliation'
                  ) + '*'
                "
                :placeholder="$t('onboarding.affiliation.heading')"
                :required="true"
                :dropdownArr="affiliation.filter(i => i.valid === true)"
                :value="form.affiliation ? form.affiliation.name : null"
                @click="onSelect($event, 'affiliation', form)"
              />
            </b-col>
            <b-col cols="6">
              <Select
                :label="$t('onboarding.job.current_role.hint') + '*'"
                :placeholder="$t('onboarding.job.current_role.hint')"
                :required="true"
                :dropdownArr="roles"
                :value="form.role ? form.role.name : null"
                @click="onSelect($event, 'role', form)"
              />
            </b-col>
            <b-col cols="6" v-if="form.role && form.role.id === 47"></b-col>
            <b-col cols="6" v-if="form.role && form.role.id === 47">
              <CInput
                id="role"
                :label="$t('onboarding.job.other.please_specify')"
                :placeholder="$t('onboarding.job.current_role.hint')"
                :required="true"
                :value="form.udf_role"
                class="md-pi__form--role"
                @input="e => (form.udf_role = e)"
                :maxlength="64"
              />
            </b-col>
            <b-col cols="6">
              <Select
                :label="
                  $t(
                    'settings.my_details.professional_information.labels.level_of_education.optional'
                  )
                "
                :placeholder="
                  $t(
                    'settings.my_details.professional_information.labels.level_of_education.title'
                  )
                "
                :dropdownArr="education.filter(i => i.valid === true)"
                :value="form.education ? form.education.name : null"
                @click="onSelect($event, 'education', form)"
              />
            </b-col>
          </b-row>
        </div>
      </template>
    </settings-card>
  </div>
</template>
<script>
import SettingsMixin from "../mixin";

import SettingsCard from "../Cards/SettingsCard.vue";
import FooterButton from "../Shared/FooterButton.vue";
export default {
  mixins: [SettingsMixin],
  components: { SettingsCard, FooterButton },
  data() {
    return {
      showRoleText: false
    };
  }
};
</script>
<style lang="scss">
.md-pi__form--role {
  margin-bottom: 16px;
}
</style>
